<template>
	<CRow>
		<CCol>
			<CRow>
				<CCol md="12" class="text-right">
					<CButton
						:disabled="isMaxValue"
						class="transparent"
						color="secondary"
						data-test-id="add-video"
						@click="handleAddItem"
					>
						<CIcon class="mr-2" name="cil-plus" /> Add text link
					</CButton>
				</CCol>
			</CRow>

			<CRow>
				<CCol>
					<BannerShortcutFormLinkItem
						v-for="(vItem, index) in $v.localMediaLinks.$each.$iter"
						:key="vItem.key"
						:v-item="vItem"
						:index="Number(index)"
						class="media-item mt-3"
						data-test-id="media-item"
						placeholder="Add link here"
						@onInputImage="handleInputImage"
						@onDelete="handleDeleteItem"
						@onLinkChange="handleLinkChange"
						@onChange="handleMediaChange"
					/>
				</CCol>
			</CRow>
		</CCol>
	</CRow>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import { required, url } from 'vuelidate/lib/validators';
import BannerShortcutFormLinkItem from '@/components/BannerShortcutFormLinkItem.vue';

export default {
	name: 'BannerShortcutFormLink',

	validations: {
		localMediaLinks: {
			$each: {
				nameTh: {
					required,
				},
				nameEn: {
					required,
				},
				link: {
					required,
					url,
				},
				file: {
					required,
				},
			},
		},
	},
	components: {
		BannerShortcutFormLinkItem,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			localMediaLinks: [],
			maxValue: 4,
		};
	},
	computed: {
		isMaxValue() {
			return this.localMediaLinks.length >= this.maxValue;
		},
	},
	created() {
		this.localMediaLinks = this.value.map((media) => {
			return {
				...media,
				key: this.generateKey(),
			};
		});

		// Auto add first of field set for user when user visit in create page
		if (!this.isEdit) {
			this.handleAddItem();
		}
	},
	methods: {
		generateKey() {
			return uniqueId('media_key_');
		},
		handleAddItem() {
			if (!this.isMaxValue) {
				this.localMediaLinks.push({
					file: null,
					link: null,
					nameTh: null,
					nameEn: null,
					key: this.generateKey(),
				});

				this.emitInputValue();
			}
		},
		handleInputImage(file, index) {
			this.localMediaLinks[index].file = file;
			this.emitInputValue();
		},
		handleDeleteItem(value) {
			this.localMediaLinks = this.localMediaLinks.filter((media, index) => {
				return index !== value;
			});

			this.emitInputValue();
		},
		handleLinkChange(value, index) {
			this.localMediaLinks[index].link = value;
			this.emitInputValue();
		},

		handleNameThChange(value, index) {
			this.localMediaLinks[index].nameTh = value;
			this.emitInputValue();
		},
		handleNameEnChange(value, index) {
			this.localMediaLinks[index].nameEn = value;
			this.emitInputValue();
		},
		handleMediaChange({ key, index, value }) {
			this.localMediaLinks[index][key] = value;
		},
		emitInputValue() {
			this.$emit('input', this.localMediaLinks);
		},
	},
};
</script>
