<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="id"
			ref="general"
			:name.sync="name"
			:priority.sync="priority"
			:status.sync="status"
			:widgets.sync="widgets"
			:widget-type="widgetType"
			name-label="Shortcut set name*"
			show-widget
		/>

		<BannerDate
			ref="date"
			:status="status"
			:start-date.sync="startDate"
			:start-time.sync="startTime"
			:end-date.sync="endDate"
			:end-time.sync="endTime"
		/>

		<BannerContentWrapper
			ref="content"
			:has-banner-link="false"
			desktop-img="/assets/images/shortcut-banner-desktop.svg"
			mobile-img="/assets/images/shortcut-banner-mobile.svg"
		>
			<template #title>
				Shortcut
			</template>
			<BannerShortcutFormLink
				ref="media"
				v-model="media"
				:is-edit="isEdit"
				class="mt-4 mb-5"
			/>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:is-edit="isEdit"
			:remove-text="isEdit ? 'Remove banner' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'MicrositeBannerShortcutList'})"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteBanner.bind(null, id)"
			title="Remove this banner?"
			description="By removing this, all banner info will be disappeared from the list and linked widgets."
			@onSuccess="$router.push({ name: 'BannerSmallList'})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BannerShortcutFormLink from '@/components/BannerShortcutFormLink.vue';

import { BANNER_TYPE, BANNER_IMAGE_TYPE } from '../enums/banners';
import { WIDGET_TYPE } from '../enums/widgets';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'BannerShortcutForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
		BannerShortcutFormLink,
	},

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
		bannerType: {
			type: String,
			default: BANNER_TYPE.shortcut,
		},
	},

	data() {
		return {
			id: null,
			name: '',
			priority: 0,
			widgets: [],
			status: true,
			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',
			banner: null,
			bannerLink: '',
			media: [],
		};
	},

	computed: {
		widgetType() {
			return WIDGET_TYPE[this.bannerType];
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEdit && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.priority = value.priority;
					this.status = value.status;
					this.startDate = value.startDate;
					this.startTime = value.startTime;
					this.endDate = value.endDate;
					this.endTime = value.endTime;
					this.banner = value.banners.banner;
					this.bannerLink = value.banners.bannerLink;
					this.widgets = value.widgets;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteBanner: 'banners/deleteBanner',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		addBanner(file, type, link) {
			const result = {};
			result.file = file ? file.id : null;
			result.type = type;

			if (link) {
				result.link = link;
			}

			return result;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;
			const contentRef = this.$refs.content.$v;
			const mediaRef = this.$refs.media.$v;

			generalRef.$touch();
			dateRef.$touch();
			contentRef.$touch();
			mediaRef.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentRef.$invalid &&
				!mediaRef.$invalid
			) {
				const data = {
					name: this.name,
					type: BANNER_TYPE.shortcut,
					priority: Number(this.priority, 10),
					is_visible: this.status,
					start_at: convertDateTimeToUTC(this.startDate, this.startTime),
					end_at: convertDateTimeToUTC(this.endDate, this.endTime),
					banners: [
						this.addBanner(
							this.banner,
							BANNER_IMAGE_TYPE.homepage_small_banner_image,
							this.bannerLink,
						),
					],

					widget_ids: this.widgets.map((widget) => widget.id),
				};

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.remove-banners {
		font-size: rem(14);
		color: $color-orange;

		align-items: center;

		cursor: pointer;
	}
</style>
