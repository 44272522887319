<template>
	<BannerShortcutForm
		:banner-type="bannerType"
	/>
</template>

<script>
import BannerShortcutForm from '@/components/BannerShortcutForm.vue';
import { BANNER_TYPE } from '../enums/banners';

export default {
	name: 'BannerShortcutCreate',

	components: {
		BannerShortcutForm,
	},
	props: {
		bannerType: {
			type: String,
			default: BANNER_TYPE.shortcut,
		},
	},
};
</script>