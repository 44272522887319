<template>
	<div class="mt-4">
		<div class="position-relative d-flex justify-content-between">
			<BaseInputImage
				:thumbnail="showPreviewImage(previewImage)"
				:is-valid="!vItem.file.$error"
				title="Add image"
				class="mt-2 mr-4"
				@onClick="handleClickPreviewImage"
				@onDelete="handleDeletePreviewImage"
			/>

			<div class="d-flex w-100 input-container">
				<div class="w-100">
					<CRow>
						<CCol md="6">
							<CInput
								v-model.trim="vItem.nameEn.$model"
								:is-valid="!vItem.nameEn.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`Shortcut name (EN) #${index + 1}`"
								class="w-100 mb-0"
								type="text"
								description="Recommend 16 characters"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="vItem.nameTh.$model"
								:is-valid="!vItem.nameTh.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`Shortcut name (TH) #${index + 1}`"
								class="w-100 mb-0"
								type="text"
								description="Recommend 16 characters"
							/>
						</CCol>
						<CCol md="12" class="mt-3">
							<CInput
								v-model.trim="vItem.link.$model"
								:is-valid="!vItem.link.$error && null"
								:invalid-feedback="$t('global.error.url')"
								:label="`Shortcut link #${index + 1}`"
								class="w-100 mb-0"
								type="text"
								placeholder="Add link here"
							/>
						</CCol>
					</CRow>
				</div>
				<CButton
					class="btn-delete-item"
					@click="handleDeleteItem"
				>
					<CIcon name="cil-trash" />
				</CButton>
			</div>
		</div>

		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitMedia"
		/>
	</div>
</template>

<script>
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';
import { getThumbnailFromMediaObject } from '../assets/js/helpers';

export default {
	name: 'BannerShortcutFormLinkItem',
	components: {
		ModalMediaLibrary,
	},
	props: {
		link: {
			type: String,
			default: null,
		},
		file: {
			type: Object,
			default: () => ({}),
		},
		vItem: {
			type: Object,
			default: () => ({}),
		},
		index: {
			type: Number,
			default: 0,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			localLink: null,
			previewImage: null,
		};
	},
	methods: {
		showPreviewImage(image) {
			return getThumbnailFromMediaObject(image);
		},

		handleClickPreviewImage() {
			this.$refs['media-library'].open();
		},

		handleDeletePreviewImage() {
			this.previewImage = null;

			this.$emit('onInputImage', null, this.index);
		},

		handleDeleteItem() {
			this.$emit('onDelete', this.index);
		},

		handleSubmitMedia(images) {
			this.previewImage = {
				...images[0],
				urls: images[0].imageUrls,
			};

			this.$emit('onInputImage', images[0], this.index);
		},
	},
};
</script>

<style lang="scss" scoped>
	.input-container {
		margin-right: rem(54);
	}

	.btn-delete-item {
		position: absolute;
		top: rem(30);
		right: 0;
		color: $color-black-25;
	}
</style>
